<template>
  <div class="main">
    <Head />
    <div class="help-content">
      <div class="help-box">
        <ul>
          <li>
            <div class="bottom problem">
              <div class="p_content">
                <p class="p_title title" style="text-align: center">
                  商务合作
                </p>
                <!-- <p class="p_title">合作介绍</p> -->
                <p class="p_icon">
                  上海域起网络科技有限公司成立于 2013年，始终秉承“通过科技和艺术改变世界”的文化使命，是一家集游戏研发和发行于一体的高新技术企业，2020年被评定为浦东新区企业研发机构、浦东新区高成长性总部，并在2021年成为上海市第一批重点机构中的一员。
                </p>
                <p class="p_icon">
                  公司拥有5A级办公环境，积极推崇硅谷文化。公司规模近400人，核心成员曾服务于华为、腾讯、网易、莉莉丝等多家知名企业。公司自主研发并发行了《GG游戏大厅》、《Garden Affairs》等多款游戏，并于近年分别独家代理了以二次元“东方Project”为原型的二次创作手游《东方LostWord》、由国内知名团队研发的Match-3手游《Home Makeover》等，在全球拥有上亿注册用户。
                </p>
                <p class="p_icon">
                  通过自主研发、发行与代理发行并行，域起网络未来将开拓更多领域，进一步完善公司产业线布局，立足全球化视野，开拓产业发展战略新步伐。
                </p>
                <div class="p_content">
                  <p class="p_line" />
                  <p class="p_title">联系我们：</p>
                  <p class="p_icon">电话：400-9031-158</p>
                  <p class="p_icon">邮件：business@gg.com</p>
                  <img src="@/assets/image/pc/home/map.png" alt="">
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <Foot />
  </div>
</template>

<script>
import Head from '../modules/Head.vue'
import Foot from '../modules/Foot.vue'
export default {
  name: 'Cooperation',
  components: { Head, Foot }
}
</script>

<style scoped>
.p_line{
  height: 1rem;
}
.help-content {
   padding-top:3.575rem;
  background-color: #f5f5f5;
  display: flex;
  justify-content: center;
  align-items: center;
}
.help-content .help-box {
  margin: 1.25rem 0;
  overflow: hidden;
  width: 35.625rem;
  background-color: #fff;
  padding: 0.8125rem;
  box-sizing: border-box;
  border-radius: 0.1875rem;
  border: 0.0625rem solid #eee;
}
.help-content .help-box li {
  background-color: #f5f5f5;
  border-radius: 0.1875rem;
  font-size: 0.5625rem;
  margin-bottom: 0.625rem;
}

.help-content .help-box li:last-of-type {
  margin-bottom: 0;
}

.help-content .help-box li .problem {
  padding: 0 1.25rem 0.625rem 1.25rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.help-content .help-box li .problem .content {
  padding: 0.625rem;
  margin-top: 0.625rem;
  background-color: #f5f5f5;
  border-radius: 0.1875rem;
  border: 0.0325rem solid #e7e7e7;
  font-size: 0.4375rem;
  display: flex;
  flex-direction: column;
  vertical-align: top;
}

.help-content .help-box li .bottom {
  border-top: 0.0325rem solid #eee;
  display: block;
}

.problem p {
  cursor: pointer;
}

.p_content .p_title {
  font-size: 0.5587rem;
  font-weight: 700;
  line-height: 1.5rem;
}

.p_content .p_icon {
  text-indent: 3.8em;
  line-height: 1.2rem;
  font-size: 0.4375rem;
}
.p_content img {
  width:100%
}
</style>
