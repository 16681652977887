import { render, staticRenderFns } from "./Cooperation.vue?vue&type=template&id=27b1a3ce&scoped=true&"
import script from "./Cooperation.vue?vue&type=script&lang=js&"
export * from "./Cooperation.vue?vue&type=script&lang=js&"
import style0 from "./Cooperation.vue?vue&type=style&index=0&id=27b1a3ce&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "27b1a3ce",
  null
  
)

export default component.exports